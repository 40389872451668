<template>
  <section class="product-card" :class="{ large }">
    <div class="image-container">
      <div class="gradient" />
      <div class="top-banner-image">
        <div v-if="product.label" class="favorite" :class="{ 't-large': large }">
          {{ product.label }}
        </div>
        <Icon
          :name="isLiked ? 'heart-active' : 'heart-inactive'"
          class="like-button prevent-slide"
          @click.prevent="handleLike(!isLiked)"
        />
      </div>
      <ProductCardImageSlider :images="product.imageSlugs" :large="large" />
      <ShadowedString class="product-name" :text="props.product.name" />
    </div>
    <div class="bottom-container">
      <div class="information-container">
        <div class="top-info">
          <div>
            <Icon
              :name="product.typeCategories.icon"
              class="info-icon"
              stroke="var(--color-gold)"
              width="22px"
              height="22px"
            />
            <span>
              {{ product.typeCategories.name }}
            </span>
          </div>
          <div v-if="product.ratings?.average">
            <Icon name="star-empty" class="info-icon" width="12px" height="12px" />
            <span> {{ product.ratings.average }}/5 </span>
          </div>
        </div>
        <div class="days-nights-info">
          <div>
            <Icon
              name="day"
              class="info-icon"
              stroke="var(--color-green)"
              width="22px"
              height="22px"
            />
            <span> {{ product.days }} jours </span>
          </div>
          <div>
            <Icon
              name="night"
              class="info-icon night-icon"
              stroke="var(--color-green)"
              width="16px"
              height="16px"
            />
            <span> {{ product.nights }} nuits </span>
          </div>
        </div>
        <div v-if="allLocations" class="locations">
          <Icon name="map-point-outline" class="info-icon" width="16px" height="16px" />
          <span class="locations-info">{{ allLocations }}</span>
        </div>
      </div>
      <div class="price-info">
        <div class="from-price-wrapper">
          <span class="from-price">à partir de</span>
          <div class="price">{{ formatPrice(product.price) }}</div>
        </div>
        <span class="discover-button">En découvrir +</span>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { JourneyModel } from '@/lib/types/models/journey';

const props = withDefaults(
  defineProps<{
    product: JourneyModel;
    large?: boolean;
  }>(),
  { large: false }
);

const isLiked = ref(false);

const allLocations = computed(() => [...new Set(props.product.locations)].join(', '));

watchEffect(() => (isLiked.value = props.product.isLiked));

function handleLike(value: boolean): void {
  isLiked.value = value;
}
</script>

<style lang="scss" scoped>
@use 'sass:color';
@use '$/components/shadowed-text.scss';
@use '$/border-radius.scss';
@use '$/button.scss';
@use '$/colors.scss';
@use '$/mouse-behavior.scss';
@use '$/breakpoints.scss';

.product-card {
  cursor: pointer;

  flex-direction: column;

  width: 100%;
  min-width: 300px;
  height: 460px;

  background-color: white;
  border: solid 1px colors.$grey-500;
  border-radius: border-radius.$small;

  &.large {
    width: 355px;

    .image-container {
      height: 290px;

      .top-banner-image .favorite {
        padding: 9px 10px;
        font-size: 15px;
      }
    }
  }

  .image-container {
    position: relative;

    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    height: 245px;
    margin-top: -1px;

    .top-banner-image {
      z-index: 2;
      transform: translateZ(0);

      align-items: center;
      justify-content: space-between;

      height: fit-content;
      margin: 16px;

      .favorite {
        align-items: center;

        height: 27px;
        padding: 7px 5px;

        font-size: 13px;
        font-weight: 600;
        color: colors.$gold;

        background-color: white;
        border-radius: border-radius.$small;
      }

      .like-button {
        @include mouse-behavior.clickable-lightness($color: #e7004c, $lightness-diff: 10%);

        position: absolute;
        top: 0;
        right: 0;
        margin: 16px;
      }
    }

    .product-name {
      @include shadowed-text.small();

      z-index: 2;
      margin: 10px 16px 16px;
      font-size: 23px;
    }

    .gradient {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      transform: translateZ(0);

      width: 100%;
      height: 100%;

      background: linear-gradient(1deg, #000 0.8%, rgb(0 0 0 / 0%) 35%);
    }
  }

  .bottom-container {
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 16px 16px;

    .information-container {
      flex-direction: column;

      .top-info {
        justify-content: space-between;

        span {
          color: colors.$gold;
        }
      }

      .days-nights-info {
        gap: 10px;
        margin-top: 10px;

        span {
          color: colors.$green;
        }
      }

      .info-icon {
        margin-right: 5px;
      }

      .night-icon {
        margin-left: 5px;
      }

      .locations {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .locations-info {
          overflow: hidden;
          display: inline-block;

          color: colors.$grey-700;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .price-info {
    justify-content: space-between;
    margin-top: 24px;
    color: colors.$green;

    .from-price-wrapper {
      flex-direction: column;

      .from-price {
        color: colors.$green;
      }

      .price {
        font-size: 28px;
        font-weight: 600;
        line-height: 0.9;
      }
    }

    .discover-button {
      @include button.outlined($color-hover: colors.$green, $size: small);

      align-self: flex-end;

      width: 127px;
      width: 50%;
      height: 36px;
      padding-right: 12px;
      padding-left: 12px;
    }
  }
}

@include breakpoints.mobile() {
  .product-card {
    height: auto;

    &.large {
      width: 310px;
      min-height: 423px;
    }
  }

  .bottom-container {
    .locations {
      .locations-info {
        max-width: 300px;
      }
    }
  }
}
</style>
